import { Button, Form, Spinner, Table } from "react-bootstrap";
import { useState } from "react";
import Cookies from "js-cookie";
import regenerateToken from "../regenerateTokens";
import { positions, useAlert } from "react-alert";

const BackPageConfig = ({
  backpageMap,
  retrieveExistingStateConfiguration,
}) => {
  const alert = useAlert();
  const [backpageBase64, setBackpageBase64] = useState("");
  const [backpageIdentifier, setBackpageIdentifier] = useState("default");
  const [searchBackpage, setSearchBackpage] = useState("");
  const [backPageUploadError, setBackPageUploadError] = useState("");
  const [myFile, setMyFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [getLoader, setGetloader] = useState(false);
  const [backpageUrl, setBackpageUrl] = useState("");

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    } else {
      return "";
    }
  };

  const handleBackpageUpload = (event) => {
    let files = event.target.files;
    let fileSize = files[0]?.size;
    let fileSizeInMb = fileSize / (1024 * 1024);

    setBackPageUploadError("");
    if (files?.length > 1) {
      alert.error(
        <span className="alertError">Please upload only 1 file.</span>,
        {
          position: positions.TOP_CENTER,
        }
      );

      return;
    }
    if (files[0]?.type != "application/pdf") {
      alert.error(
        <span className="alertError">Please upload a pdf file.</span>,
        {
          position: positions.TOP_CENTER,
        }
      );

      return;
    }
    if (fileSize && fileSizeInMb > 1.5) {
      alert.error(
        <span className="alertError">
          File size should be less than 1.5 MB
        </span>,
        {
          position: positions.TOP_CENTER,
        }
      );
      return;
    }

    setMyFile(files[0]);
  };

  const ErrorElement = ({ message }) => {
    return <p style={{ color: "red" }}>{message}</p>;
  };

  const saveBase64 = async (e) => {
    e.preventDefault();
    let fileBase64 = await convertBase64(myFile);
    if (fileBase64) setBackpageBase64(fileBase64);
  };

  const uploadBackpage = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    let payload = {
      backPageIdentifier: backpageIdentifier,
      backPageBase64: backpageBase64,
    };

    const apiUrl =
      "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/assets/backpage";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(apiUrl, options);

    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      const response = await uploadBackpage();
      if (response.status === 401) {
        await regenerateToken();
        const response = await uploadBackpage();
        const data = await response.json();

        if (data.isSuccess) {
          alert.success(<span>Backpage uploaded succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        }
      } else {
        const data = await response.json();
        if (data.isSuccess) {
          alert.success(<span>Backpage uploaded succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        } else {
        }
      }
    } catch (e) {
      alert.error(
        <span className="alertError">
          Something went wrong. Please try again.
        </span>,
        {
          position: positions.TOP_CENTER,
        }
      );
    } finally {
      setBackpageIdentifier("default");
      setLoader(false);
      retrieveExistingStateConfiguration();
    }
  };

  const fetchBackPage = async () => {
    const idToken = Cookies.get("idToken");
    const options = {
      method: "GET",
      headers: {
        Authorization: idToken,
      },
    };

    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/assets/backpage?backpageIdentifier=${searchBackpage}`;

    const response = await fetch(apiUrl, options);

    return response;
  };

  const getBackPage = async () => {
    setGetloader(true);
    try {
      const response = await fetchBackPage();
      if (response.status === 401) {
        await regenerateToken();
        const response = await fetchBackPage();
        const data = await response.json();

        if (data.isSuccess) {
          setBackpageUrl(data.data.backpageUrl);
          alert.success(<span>Backpage fetched succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        }
      } else {
        const data = await response.json();

        if (data.isSuccess) {
          setBackpageUrl(data.data.backpageUrl);
          alert.success(<span>Backpage fetched succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        } else {
        }
      }
    } catch (e) {
      alert.error(
        <span className="alertError">
          Something went wrong. Please try again.
        </span>,
        {
          position: positions.TOP_CENTER,
        }
      );
    } finally {
      setGetloader(false);
    }
  };

  const downloadBackpage = (fileName, link) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div>
      <div>
        <div className="d-flex flex-wrap">
          <div className="flex-grow-1 mb-2">
            <form onSubmit={handleSubmit}>
              <Form.Group
                style={{ width: "70%" }}
                controlId="formFile"
                className="mb-1"
              >
                <Form.Label>Backpage Identifier</Form.Label>
                <Form.Control
                  type="text"
                  value={backpageIdentifier}
                  required
                  onChange={(e) => setBackpageIdentifier(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                style={{ width: "70%", marginTop: "10px" }}
                controlId="formFile"
                className="mb-1"
                required
              >
                <Form.Label>Upload Backpage</Form.Label>
                <Form.Control
                  type="file"
                  accept="application/pdf"
                  onChange={(event) => handleBackpageUpload(event)}
                />
                <p style={{ color: "grey" }}>
                  Only pdf file with size less than 1.5 MB is allowed
                </p>
              </Form.Group>

              <div className="d-flex mt-3">
                <button
                  className="btn btn-primary me-3"
                  onClick={saveBase64}
                  disabled={!myFile}
                >
                  Save
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!backpageBase64}
                  style={{ width: "80px" }}
                >
                  {loader ? <Spinner animation="border" size="sm" /> : "Upload"}
                </button>
              </div>
            </form>
          </div>
          <div className="flex-grow-1 d-flex justify-content-lg-center align-items-start">
            <div>
              <h4>Existing Back Pages</h4>

              <Table striped bordered size="sm" style={{ width: "400px" }}>
                <thead>
                  <tr>
                    <th style={{ width: "5%", textAlign: "center" }}>SN</th>
                    <th style={{ width: "30%" }}>Label</th>
                    <th style={{ width: "10%", textAlign: "center" }}>Link</th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: "120px", overflowY: "auto" }}>
                  {typeof backpageMap === "object" &&
                  !Object.keys(backpageMap).length ? (
                    <div
                      className="p-2"
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #dee2e6",
                        borderRight: "1px solid #dee2e6",
                        borderLeft: "1px solid #dee2e6",
                      }}
                    >
                      <p>No data found</p>
                    </div>
                  ) : typeof backpageMap === "object" &&
                    Object.keys(backpageMap).length ? (
                    <>
                      {Object.keys(backpageMap).map((each, idx) => (
                        <tr key={idx + 1}>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            {idx + 1}
                          </td>
                          <td style={{ width: "30%" }}>{each}</td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            {/* <Button
                              variant="primary"
                              size="sm"
                              onClick={() =>
                                downloadBackpage(each, backpageMap[each])
                              }
                            >
                              Download
                            </Button> */}
                            <a href={backpageMap[each]} download>
                              Download
                            </a>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p className="p-2 text-center">No data found</p>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackPageConfig;
