import { useEffect, useState } from "react";
import { positions, useAlert } from "react-alert";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import Cookies from "js-cookie";
import regenerateToken from "../regenerateTokens";
import CoverPageTemplates from "./CoverPageTemplates";

const CoverPageConfig = ({
  coverpageMap,
  retrieveExistingStateConfiguration,
}) => {
  const alert = useAlert();
  const [coverPageLabel, setCoverPageLabel] = useState("default");
  const [coverpageBase64, setCoverpageBase64] = useState("");

  const [loader, setLoader] = useState(false);
  const [getLoader, setGetLoader] = useState(false);
  const [myFile, setMyFile] = useState("");
  const [coverPageUrl, setCoverPageUrl] = useState("");
  const [searchCoverPage, setSearchCoverPage] = useState("");
  const [showTemplate, setShowTemplate] = useState(false);
  const [selectedType, setSelectedType] = useState("template");
  const [fileExt, setFileExt] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const handleClose = () => setShowTemplate(false);
  const handleShow = () => setShowTemplate(true);

  const convertBase64 = (file) => {
    if (file) {
      switch (file.type) {
        case "image/svg+xml":
          setFileExt("svg");
          break;
        case "image/png":
          setFileExt("png");
          break;
        case "image/jpg":
          setFileExt("jpg");
          break;
        case "image/jpeg":
          setFileExt("jpeg");
          break;
        default:
          setFileExt("jpg");
      }
    }
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    } else {
      return "";
    }
  };

  const validateImageType = (fileName) => {
    let result =
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".jpeg") ||
      fileName.endsWith(".png") ||
      fileName.endsWith(".svg");
    return !result;
  };

  const handleCoverpageUpload = (event) => {
    let files = event.target.files;
    let fileSize = files[0]?.size;
    let fileSizeInMb = fileSize / (1024 * 1024);

    if (files?.length > 1) {
      alert.error(
        <span className="alertError">Please upload only 1 file.</span>,
        {
          position: positions.TOP_CENTER,
        }
      );

      return;
    }
    if (
      !files[0]?.type.startsWith("image/") ||
      validateImageType(files[0]?.name)
    ) {
      alert.error(
        <span className="alertError">
          Only image files having JPG, JPEG, PNG, and SVG formats are allowed!
        </span>,
        {
          position: positions.TOP_CENTER,
        }
      );

      return;
    }
    if (fileSize && fileSizeInMb > 1) {
      alert.error(
        <span className="alertError">File size should be less than 1 MB</span>,
        {
          position: positions.TOP_CENTER,
        }
      );
      return;
    }

    setMyFile(files[0]);
  };

  const saveBase64 = async (e) => {
    e.preventDefault();
    let fileBase64 = await convertBase64(myFile);
    if (fileBase64) setCoverpageBase64(fileBase64);
  };

  const uploadCoverPage = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    let payload = {
      type: "custom",
      fileType: fileExt,
      identifier: coverPageLabel,
      coverPageBase64: coverpageBase64,
    };

    const apiUrl =
      "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/assets/coverpage";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(apiUrl, options);

    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedType === "template" && !coverPageLabel) {
      alert.error(
        <span className="alertError">Cover page label cannot be empty.</span>,
        {
          position: positions.TOP_CENTER,
        }
      );
      return;
    }
    setLoader(true);

    try {
      const response = await uploadCoverPage();
      if (response.status === 401) {
        await regenerateToken();
        const response = await uploadCoverPage();
        const data = await response.json();

        if (data.isSuccess) {
          alert.success(<span>Cover page uploaded succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        }
      } else {
        const data = await response.json();
        if (data.isSuccess) {
          alert.success(<span>Cover page uploaded succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        } else {
        }
      }
    } catch (e) {
      alert.error(
        <span className="alertError">
          Something went wrong. Please try again.
        </span>,
        {
          position: positions.TOP_CENTER,
        }
      );
    } finally {
      setCoverPageLabel("default");
      setLoader(false);
      setCoverpageBase64("");
      setFileExt("");
      setSelectedTemplate("");
      retrieveExistingStateConfiguration();
    }
  };

  const fetchCoverPage = async () => {
    const idToken = Cookies.get("idToken");
    const options = {
      method: "GET",
      headers: {
        Authorization: idToken,
      },
    };

    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/assets/coverpage?coverpageIdentifier=${searchCoverPage}`;

    const response = await fetch(apiUrl, options);

    return response;
  };

  const getCoverPage = async () => {
    setGetLoader(true);
    try {
      const response = await fetchCoverPage();
      if (response.status === 401) {
        await regenerateToken();
        const response = await fetchCoverPage();
        const data = await response.json();

        if (data.isSuccess) {
          setCoverPageUrl(data.data.coverpageUrl);
          alert.success(<span>Cover page fetched succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        }
      } else {
        const data = await response.json();

        if (data.isSuccess) {
          setCoverPageUrl(data.data.coverpageUrl);
          alert.success(<span>Cover page fetched succesfully!</span>, {
            position: positions.TOP_CENTER,
          });
        } else {
        }
      }
    } catch (e) {
      alert.error(
        <span className="alertError">
          Something went wrong. Please try again.
        </span>,
        {
          position: positions.TOP_CENTER,
        }
      );
    } finally {
      setGetLoader(false);
    }
  };

  const downloadCoverpage = () => {
    const downloadLink = document.createElement("a");
    const fileName = coverPageUrl.split("coverpage/")[1];

    downloadLink.href = coverPageUrl;
    downloadLink.target = "_blank";
    downloadLink.download = fileName;

    downloadLink.click();
  };

  return (
    <div>
      <div className="d-flex flex-wrap">
        <div className="flex-grow-1 mb-2">
          <form onSubmit={handleSubmit}>
            <Form.Group
              style={{ width: "70%" }}
              controlId="formFile"
              className="mb-1"
            >
              <Form.Label>Cover Page Label</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                type="text"
                value={coverPageLabel}
                required
                onChange={(e) => setCoverPageLabel(e.target.value)}
              />
            </Form.Group>

            {/* custom & template buttons */}
            <div>
              <Form>
                <Form.Check
                  inline
                  label="Choose Template"
                  name="group1"
                  type="radio"
                  checked={selectedType === "template"}
                  onChange={() => setSelectedType("template")}
                />
                <Form.Check
                  inline
                  label="Custom Upload"
                  name="group1"
                  type="radio"
                  checked={selectedType === "custom"}
                  onChange={() => setSelectedType("custom")}
                />
              </Form>
            </div>
            {selectedType === "custom" ? (
              <Form.Group
                style={{ width: "70%", marginTop: "10px" }}
                controlId="formFile"
                className="mb-1"
                required
              >
                <Form.Label>Upload Cover Page</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleCoverpageUpload(event)}
                />
                <p style={{ color: "grey" }}>
                  Only image file with size less than 1 MB is allowed
                </p>
              </Form.Group>
            ) : (
              <div>
                {selectedTemplate && coverpageBase64 ? (
                  <div
                    className="my-2 p-2"
                    style={{
                      borderRadius: "6px",
                      background: "#e3d8d8",
                      width: "70%",
                    }}
                  >
                    Chosen Template: {selectedTemplate}
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShow();
                    }}
                  >
                    View Templates
                  </button>
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!coverpageBase64}
                    style={{ width: "140px" }}
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Upload template"
                    )}
                  </Button>
                  <CoverPageTemplates
                    showTemplate={showTemplate}
                    handleClose={handleClose}
                    setCoverpageBase64={setCoverpageBase64}
                    handleSubmit={handleSubmit}
                    saveBase64={saveBase64}
                    coverpageBase64={coverpageBase64}
                    setSelectedTemplate={setSelectedTemplate}
                    setFileExt={setFileExt}
                  />
                </div>
              </div>
            )}

            {selectedType === "custom" ? (
              <div className="d-flex mt-3">
                <button
                  className="btn btn-primary me-3"
                  onClick={saveBase64}
                  disabled={!myFile}
                >
                  Save
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!coverpageBase64}
                  style={{ width: "80px" }}
                >
                  {loader ? <Spinner animation="border" size="sm" /> : "Upload"}
                </button>
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
        <div className="flex-grow-1 d-flex justify-content-lg-center align-items-start">
          <div>
            <h4>Existing Cover Pages</h4>

            <Table striped bordered size="sm" style={{ width: "400px" }}>
              <thead>
                <tr>
                  <th style={{ width: "5%", textAlign: "center" }}>SN</th>
                  <th style={{ width: "30%" }}>Label</th>
                  <th style={{ width: "10%", textAlign: "center" }}>Link</th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: "120px", overflowY: "auto" }}>
                {typeof coverpageMap === "object" &&
                !Object.keys(coverpageMap).length ? (
                  <div
                    className="p-2"
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid #dee2e6",
                      borderRight: "1px solid #dee2e6",
                      borderLeft: "1px solid #dee2e6",
                    }}
                  >
                    <p>No data found</p>
                  </div>
                ) : typeof coverpageMap === "object" &&
                  Object.keys(coverpageMap).length ? (
                  <>
                    {Object.keys(coverpageMap).map((each, idx) => (
                      <tr key={idx + 1}>
                        <td style={{ width: "5%", textAlign: "center" }}>
                          {idx + 1}
                        </td>
                        <td style={{ width: "30%" }}>{each}</td>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          {/* <Button variant="primary" size="sm">
                            view
                          </Button> */}
                          <a href={coverpageMap[each]} target="_blank">
                            View
                          </a>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <p className="p-2 text-center">No data found</p>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverPageConfig;
