import { useEffect, useState } from "react";
import BackPageConfig from "./BackPageConfig";
import CoverPageConfig from "./CoverPageConfig";
import regenerateToken from "../regenerateTokens";
import { positions, useAlert } from "react-alert";
import { CircularProgress, Stack } from "@mui/material";

const AssetManagement = () => {
  const alert = useAlert();
  const [stateConfig, setStateConfig] = useState("");
  const [stateLoader, setStateloader] = useState(false);

  useEffect(() => {
    retrieveExistingStateConfiguration();
  }, []);

  async function retrieveExistingStateConfiguration() {
    setStateloader(true);
    try {
      const existingStateConfigResponse = await getStateConfiguration();

      const statusCode = existingStateConfigResponse.status;
      if (statusCode === 401) {
        // API Call is to be made for regenerating the ID Token.
        await regenerateToken();
        const existingStateConfigResponse = await getStateConfiguration();
        const data = await existingStateConfigResponse.json();
        if (data.isSuccess) {
          setStateConfig(data.data.stateData);
        }
      } else {
        const data = await existingStateConfigResponse.json();
        if (data.isSuccess) {
          setStateConfig(data.data.stateData);
        }
      }
    } catch (error) {
      console.log("error", error.message);
      alert.error(
        <span className="alertError">
          Failed to load state data. Please try again
        </span>,
        {
          position: positions.TOP_CENTER,
        }
      );
    } finally {
      setStateloader(false);
    }
  }

  const getStateConfiguration = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const url =
      "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/config/state";
    const options = {
      headers: {
        authorization: idToken,
      },
      method: "GET",
    };
    const existingStateConfigResponse = await fetch(url, options);
    return existingStateConfigResponse;
  };

  const loader = () => {
    return (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "45vh" }}
      >
        <CircularProgress />
      </Stack>
    );
  };

  return (
    <div>
      {stateLoader ? (
        loader()
      ) : (
        <div className="px-3 py-3">
          <BackPageConfig
            backpageMap={stateConfig?.backpageMap}
            retrieveExistingStateConfiguration={
              retrieveExistingStateConfiguration
            }
          />
          <hr></hr>
          <div className="mt-3">
            <CoverPageConfig
              coverpageMap={stateConfig.coverpageMap}
              retrieveExistingStateConfiguration={
                retrieveExistingStateConfiguration
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetManagement;
