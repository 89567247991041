import { useState } from "react";
import { positions, useAlert } from "react-alert";
import { Button, Carousel, Modal } from "react-bootstrap";

const CoverPageTemplates = ({
  showTemplate,
  handleClose,
  setCoverpageBase64,
  handleSubmit,
  saveBase64,
  coverpageBase64,
  setSelectedTemplate,
  setFileExt,
}) => {
  const templateDetails = [
    {
      label: "option-1",
      sample: "coverpage1.svg",
    },
    {
      label: "option-2",
      sample: "coverpage2.svg",
    },
    {
      label: "option-3",
      sample: "coverpage3.svg",
    },
    {
      label: "option-4",
      sample: "coverpage4.svg",
    },
    {
      label: "option-5",
      sample: "coverpage5.svg",
    },
    {
      label: "option-6",
      sample: "coverpage6.svg",
    },
    {
      label: "option-7",
      sample: "coverpage7.svg",
    },
  ];
  const alert = useAlert();
  const [chosenTemplate, setChosenTemplate] = useState({});

  const handleTemplateSelection = (option) => {
    if (chosenTemplate.hasOwnProperty(option.label)) {
      setChosenTemplate({});
      setSelectedTemplate("");
      setFileExt("");
    } else {
      setChosenTemplate({ [option.label]: true, link: option.sample });
      setSelectedTemplate(option.label);
      setFileExt(option.sample.split(".")[1]);
    }
  };

  const downloadTemplate = (data) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = data.sample;
    const fileName = data.label;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const convertImageToBase64 = async () => {
    try {
      // Fetch the image directly from the public folder
      const response = await fetch(
        `${process.env.PUBLIC_URL}/${chosenTemplate.link}`
      );

      if (!response.ok) {
        alert.error(<span className="alertError">Failed to load image.</span>, {
          position: positions.TOP_CENTER,
        });
        return;
      }

      // Convert the response to a Blob
      const blob = await response.blob();
      const reader = new FileReader();

      // Convert Blob to Base64
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result;
        console.log("Base64 String:", base64String);
        setCoverpageBase64(base64String);
      };
    } catch (error) {
      console.error("Error converting image to Base64:", error);
      alert.error(
        <span className="alertError">Error converting image to Base64:</span>,
        {
          position: positions.TOP_CENTER,
        }
      );
    } finally {
      handleClose();
    }
  };

  console.log("chosedTemplate", chosenTemplate);

  return (
    <div>
      <Modal show={showTemplate} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cover Page Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Carousel variant="dark" interval={null} indicators={false}>
              {templateDetails.map((each, idx) => (
                <Carousel.Item key={each.label}>
                  <div>
                    <div className="d-flex justify-content-around align-items-center p-1">
                      <h3 style={{ margin: "0" }}>{each.label}</h3>
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-light"
                          onClick={() => downloadTemplate(each)}
                        >
                          <i class="fas fa-arrow-alt-circle-down fa-lg"></i>
                        </button>
                        <div className="ms-2">
                          <button
                            className="btn btn-light"
                            onClick={() => handleTemplateSelection(each)}
                          >
                            {!chosenTemplate[each.label] ? (
                              <i class="far fa-circle fa-lg"></i>
                            ) : (
                              <i
                                class="fas fa-check-circle fa-lg"
                                style={{ color: "green" }}
                              ></i>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="p-2" style={{ background: "#dfe1e6" }}>
                      <img
                        src={`/${each.sample}`}
                        alt={each.label}
                        width="100%"
                        height="350px"
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={convertImageToBase64}
            disabled={!Object.keys(chosenTemplate).length}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CoverPageTemplates;
